import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "components/Layout"
import Seo from 'components/Seo'

const PrecisionAgPage = () => {

  /**
   * Renders the main navigation area
   * @returns {JSX.Element}
   */
   const CTAButtons = () => {
    return(
      <nav className="grid grid-cols-1 mt-4 mb-6 text-center text-black border-y-2 md:gap-4 md:border-y-0 border-tecsight-blue">
        <OutboundLink 
          href="mailto:pa@tecsight.com.au"
          className="flex flex-col items-center justify-between p-8 font-bold bg-tecsight-green md:rounded-lg"
          >
          <FontAwesomeIcon 
            size="2x" 
            className="fill-current" 
            icon={faEnvelope} />
          <span className="pt-6">Email Support</span>
        </OutboundLink>
      </nav>
    );
  }
  
  return(
    <Layout>
      <Seo 
        title="Field Specialist"
        description="Our specialist Precision Ag team providing actionable insights to improve your farm’s sustainability, profitability and market agility."
      />
      <h1 className="mb-2 text-3xl font-bold text-center text-tecsight-blue">Precision Ag</h1>
      <CTAButtons/>
      <article className="px-4 text-gray-600">
      <p className="mb-4">
        Technology, data and smart machinery combine to deliver the most efficient 
        and productive farm practices possible, with our specialist Precision Ag team 
        providing actionable insights to improve your farm’s sustainability, profitability 
        and market agility.
      </p>
      <p className="mb-4">
          Collect and use your farm data to improve decision making, easily share 
          information with trusted partners and monitor your operation anytime, anywhere. 
          In fact, our Precision Ag team can step in as subject matter expert when your 
          agronomist, chemical supplier or contractor needs to use John Deere 
          machines and technology.
      </p>
      <p className="mb-4">
          Our experienced team can train your employees, provide weather and 
          soil monitoring equipment or drone imaging services.
      </p>
      </article>
    </Layout>
  )
}

export default PrecisionAgPage